import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MicroBlogElement from "../components/microblog"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allMdx(
        filter: { frontmatter: { type: { in: ["blog", "newsletter"] } } }
        sort: { order: DESC, fields: [frontmatter___date] }
      ) {
        nodes {
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            slug
            category
            page
            type
          }
          slug
          id
        }
      }
      microblogs: allMdx(
        filter: { frontmatter: { type: { eq: "microblog" } } }
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 1
      ) {
        nodes {
          frontmatter {
            date
            title
            slug
          }
          body
          slug
          id
        }
      }
    }
  `)

  const {
    allMdx: { nodes },
    microblogs,
  } = data

  const latestMicroblog = microblogs.nodes[0]

  const DocLink = ({ total, i, post }) => {
    return (
      <div className="doc">
        <Link to={post.frontmatter.slug} className="link">
          <div className="title">{post.frontmatter.title}</div>
          <div className="meta">
            {post.frontmatter?.category} ~{" "}
            {new Date(post.frontmatter?.date).getFullYear()}
          </div>
        </Link>
      </div>
    )
  }

  const Docs = nodes.map((node, i) => (
    <DocLink i={i} total={nodes.length} key={node.id} post={node} />
  ))

  return (
    <Layout>
      <SEO title="Udara Jay" category={"Index"} />
      <div className="home">
        <div className="main">
          <div className="header">
            <div className="highlight">Udara</div>
            <div className="des">
              Sharing my interest in a little bit of everything, but mostly–
              technology, genetics and startups.
              <br />
            </div>
          </div>

          <a href="https://un.ms" target="_blank" className="studio">
            <div className="block">
              About my studio <b>UNMS</b>↗
            </div>
          </a>

          <div className="docs">{Docs}</div>

          <div className="latest-microblog">
            <div className="m-title">From the microblog</div>
            <div className="latest">
              <MicroBlogElement content={latestMicroblog} />
            </div>
          </div>

          <div className="opensource">
            <div className="m-title">Featured work</div>

            <div className="docs">
              <div className="doc work">
                <a href="/work#clew-2019-2022" className="link">
                  <div className="left">
                    <div className="title">Clew</div>
                    <div className="des">Better tools for digital work</div>
                  </div>
                  <div className="meta">Startup</div>
                </a>
              </div>
              <div className="doc work">
                <a href="/work#tidl-2017-19" className="link">
                  <div className="left">
                    <div className="title">Alcamy</div>
                    <div className="des">
                      The internet’s catalog of self-learning resources
                    </div>
                  </div>
                  <div className="meta">Website</div>
                </a>
              </div>
              <div className="doc work">
                <a href="https://github.com/UdaraJay/atm" className="link">
                  <div className="left">
                    <div>
                      <div className="title">Dip</div>
                      <div className="des">
                        5-minute tappable lessons, once a day
                      </div>
                    </div>
                  </div>
                  <div className="meta">App</div>
                </a>
              </div>
              <div className="doc work">
                <a href="/work#tidl-2017-19" className="link">
                  <div className="left">
                    <div className="title">Tidl</div>
                    <div className="des">Smart portfolio and resume tool</div>
                  </div>
                  <div className="meta">Startup</div>
                </a>
              </div>
            </div>
          </div>

          <div className="opensource">
            <div className="m-title">Open-source</div>

            <div className="docs">
              <div className="doc">
                <a
                  href="https://github.com/UdaraJay/pile"
                  target="_blank"
                  className="link"
                >
                  <div className="title">Pile – Reflective journaling app</div>
                  <div className="meta">GitHub</div>
                </a>
              </div>
              <div className="doc">
                <a
                  href="https://github.com/UdaraJay/atm"
                  target="_blank"
                  className="link"
                >
                  <div className="title">ATM – Personal logging CLI</div>
                  <div className="meta">NPM</div>
                </a>
              </div>
              <div className="doc">
                <a
                  href="https://github.com/UdaraJay/stats"
                  target="_blank"
                  className="link"
                >
                  <div className="title">
                    Stats – A self-hosted analytics provider
                  </div>
                  <div className="meta">GitHub</div>
                </a>
              </div>
            </div>
          </div>

          <a
            href="https://udara.substack.com"
            target="_blank"
            className="button"
          >
            <div className="newsletter">
              <div className="pitch">
                <div className="button">Newsletter</div>
                <div className="count">
                  5,689 readers <div className="line"></div>
                </div>
                <input className="input" placeholder="Your email" readOnly />
                The internet is my metaverse, data is my bread and butter, and
                design is my art form. Join my newsletter on technology, design
                and startups ✦
              </div>
            </div>
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
